<template>
  <div class="inter-wrapper">
    <HeaderInter />
    <div class="inter-content container-fluid">
      <div class="memberTicket-area">
        <div class="circle-big" />
        <div class="circle-small" />
        <div class="ufo" />
        <div class="circle-small b" />

        <div class="memberTicket-group">
          <div class="title">
            我的票夾
          </div>
          <div class="memberTicket-items">
            <router-link to="/user/info">
              <div class="left">
                <div class="icon-line">
                  <i class="icon" />
                </div>
                <p>票夾聯絡人資料</p>
              </div>
              <div class="right">
                <b-icon-arrow-right-short />
              </div>
            </router-link>
          </div>
          <div class="memberTicket-items">
            <router-link to="/user/cancel-policy">
              <div class="left">
                <div class="icon-line">
                  <i class="icon-b" />
                </div>
                <p>{{ shop.cancel_name }}</p>
              </div>
              <div class="right">
                <b-icon-arrow-right-short />
              </div>
            </router-link>
          </div>
          <div class="memberTicket-items">
            <router-link to="/user/person-policy">
              <div class="left">
                <div class="icon-line">
                  <i class="icon-c" />
                </div>
                <p>{{ shop.personal_name }}</p>
              </div>
              <div class="right">
                <b-icon-arrow-right-short />
              </div>
            </router-link>
          </div>
          <div
            v-if="shop.faq_name !== '' && shop.faq_name !== null"
            class="memberTicket-items"
          >
            <router-link to="/user/faq">
              <div class="left">
                <div class="icon-line">
                  <i class="icon-e" />
                </div>
                <p>{{ shop.faq_name }}</p>
              </div>
              <div class="right">
                <b-icon-arrow-right-short />
              </div>
            </router-link>
          </div>
          <div class="memberTicket-items">
            <router-link to="/user/about">
              <div class="left">
                <div class="icon-line">
                  <i class="icon-d" />
                </div>
                <p>關於我們</p>
              </div>
              <div class="right">
                <b-icon-arrow-right-short />
              </div>
            </router-link>
          </div>
          <div class="memberTicket-items">
            <a href="https://www.3tgds.com/policy" target="_blank">
              <div class="left">
                <div class="icon-line">
                  <i class="icon-d" />
                </div>
                <p>隱私權政策</p>
              </div>
              <div class="right">
                <b-icon-arrow-right-short />
              </div>
            </a>
          </div>
          <button
            v-if="islogin && this.$store.getters.canlogout"
            class="btn btn-next"
            type="button"
            @click="logout()"
          >
            登出
          </button>
          <button
            v-if="!islogin"
            class="btn btn-next"
            type="button"
            @click="dologin()"
          >
            登入
          </button>
        </div>
      </div>
      <div class="version-hint">
        版本 {{ appVersion }}
      </div>
    </div>
  </div>
</template>

<script>
import HeaderInter from '@/components/HeaderInter'
import { siteLogout, userLogin } from '@/utils/user'

export default {
  name: 'UserIndex',
  components: {
    HeaderInter
  },
  data() {
    return {
      appVersion:
        process.env.VUE_APP_VERSION +
        ' - ' +
        process.env.VUE_APP_VERSION_MANUAL,
      shop: {
        cancel_name: this.$store.getters.shopInfo.cancel_name,
        personal_name: this.$store.getters.shopInfo.personal_name,
        faq_name: this.$store.getters.shopInfo.faq_name
      }
    }
  },
  computed: {
    islogin() {
      const is_login = !!this.$store.getters.authInfo.token
      return is_login
    }
  },
  created() {
    // todo 確認是否可以登出/ 或是登出再返回他們APP  流程待確認

    // init fb
    if (!window.FB) {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: '572687050485535',
          cookie: true,
          xfbml: true,
          version: 'v14.0'
        })
        window.FB.AppEvents.logPageView()
      }
      ;(function(d, s, id) {
        var js
        var fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {
          return
        }
        js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    }
  },
  mounted() {
    if (!window.google) {
      window.google.accounts.id.initialize({
        client_id:
          '336842712596-eh2b17a0l3qhtvu12j9j0ggd5uige7cv.apps.googleusercontent.com'
      })
    }
  },
  methods: {
    dositeout() {
      const self = this
      siteLogout(function() {
        self.$router.push({ path: '/index' }).catch(() => {})
      })
    },
    logout() {
      const self = this
      if (this.$store.getters.logintype === 'google') {
        window.google.accounts.id.disableAutoSelect()
        self.dositeout()
      } else if (this.$store.getters.logintype === 'facebook') {
        window.FB.getLoginStatus(function(response) {
          if (response.status === 'connected') {
            window.FB.logout(function(res) {
              self.dositeout()
            })
          } else {
            self.dositeout()
          }
        })
      } else {
        siteLogout(function() {
          self.$router.push({ path: '/index' }).catch(() => {})
        })
      }
    },
    dologin() {
      userLogin()
    }
  }
}
</script>
<style scoped lang="scss">
@import 'assets/scss/common.scss';
.memberTicket-area {
  // border: 1px solid rgba($main-color, $alpha: .15);
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 8px 30px 0px rgba($main-color, $alpha: 0.15);
  margin: 1.25rem 0.5rem 0;
  // background: linear-gradient(0deg, rgba($sec-color, $alpha: 0) 80%, rgba($sec-color, $alpha: 1) 80%);
  .circle-big {
    position: absolute;
    margin: auto;
    left: -25%;
    right: 0;
    top: -120px;
    width: 150%;
    height: 180px;
    background: rgba($main-color, $alpha: 1);
    border-radius: 100%;
  }
  .circle-small {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: -15px;
    width: 30px;
    height: 30px;
    background: rgba($white-color, $alpha: 1);
    border-radius: 100%;
  }
  .ufo {
    position: absolute;
    left: 0;
    right: 0;
    height: 50px;
    background-color: $white-color;
    background-size: cover;
    -webkit-mask: url(~@/assets/images/icon-ufo.svg) no-repeat calc(50% - 65px)
      calc(100% - -12px);
    mask: url(~@/assets/images/icon-ufo.svg) no-repeat calc(50% - 65px)
      calc(100% - -12px);
  }
  .circle-small.b {
    top: auto;
    bottom: -15px;
    background: rgba($main-color, $alpha: 1);
  }
}

.memberTicket-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 2rem 2.5rem;
  position: relative;
  z-index: 9;
  .title {
    color: rgba($white-color, $alpha: 1);
    font-size: 1rem;
    text-align: center;
    margin-bottom: 1.85rem;
  }
  .memberTicket-items {
    border-bottom: 2px solid rgba($main-color, $alpha: 0.15);
    border-radius: 0 0 15px 15px;
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: rgba($main-color, $alpha: 1);
      padding: 0.5rem 1rem 0.35rem;
      transition: 0.5s;
      .left {
        flex: 0 0 70%;
        display: flex;
        .icon-line {
          width: 30px;
          height: 30px;
          border: 1px solid rgba($main-color, $alpha: 1);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            width: 20px;
            height: 20px;
            display: block;
            background-color: $main-color;
            -webkit-mask: url(~@/assets/images/icon-user.svg) no-repeat center
              center;
            mask: url(~@/assets/images/icon-user.svg) no-repeat center center;
          }
        }

        p {
          color: rgba($sec-color, $alpha: 1);
          padding: 0;
          margin: 0 0 0 0.5rem;
          line-height: 35px;
          font-size: 0.85rem;
        }
      }
      .right {
        font-size: 1.5rem;
        font-weight: bolder;
      }
    }
    a:hover {
      background: rgba($main-color, $alpha: 1);
      border-radius: 12.5px;
      .icon-line {
        border: 1px solid rgba($white-color, $alpha: 1);
        i {
          background-color: $white-color;
        }
      }
      p {
        color: rgba($white-color, $alpha: 1);
      }
      .right {
        color: rgba($white-color, $alpha: 1);
      }
    }
  }
}
.memberTicket-items .icon-b {
  width: 22px;
  height: 22px;
  display: block;
  background-color: $main-color;
  -webkit-mask: url(~@/assets/images/icon-journal-x.svg) no-repeat center center;
  mask: url(~@/assets/images/icon-journal-x.svg) no-repeat center center;
}
.memberTicket-items .icon-c {
  width: 22px;
  height: 22px;
  display: block;
  background-color: $main-color;
  -webkit-mask: url(~@/assets/images/icon-lock.svg) no-repeat center center;
  mask: url(~@/assets/images/icon-lock.svg) no-repeat center center;
}
.memberTicket-items .icon-d {
  width: 22px;
  height: 22px;
  display: block;
  background-color: $main-color;
  -webkit-mask: url(~@/assets/images/icon-about.svg) no-repeat center center;
  mask: url(~@/assets/images/icon-about.svg) no-repeat center center;
}
.memberTicket-items .icon-e {
  width: 22px;
  height: 22px;
  display: block;
  background-color: $main-color;
  -webkit-mask: url(~@/assets/images/icon-faq.svg) no-repeat center center;
  mask: url(~@/assets/images/icon-faq.svg) no-repeat center center;
}

.memberTicket-area {
  display: flex;
  flex-direction: column;
  .btn {
    flex: 0 0 100%;
    font-size: 1rem;
    border-radius: 0;
    padding: 10px;
    margin-top: 2rem;
  }
  .btn-next {
    background: rgba($main-color, $alpha: 1);
    color: $white-color;
  }
}
</style>
